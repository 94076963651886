<script setup>
  import { useTour } from '../Tour.js'
  import { onMounted, defineEmits } from 'vue'

  const emits = defineEmits(['complete','cancel']);
  const tour = useTour(emits);

  onMounted(() =>  {
    tour.addStep({
      title: 'Browsing Positions',
      text: `This is a list of the positions that are currently available to you, organized by company.`,
      buttons: [
        {
          text: 'Continue',
          action: tour.next
        },
      ]
    });

    tour.addStep({
      attachTo: { element: '.client', on: 'top' },
      title: 'Company Details',
      text: `This is the company that is offering the position.
      You can get a better feel for the company by viewing their profile.`,
    });

    tour.addStep({
      attachTo: { element: '.search', on: 'top' },
      title: 'Position Details',
      text: `You can see more details about each position by clicking on the Detailed Information button.
      `,
    });

    tour.addStep({
      title: 'Start Browsing',
      text: `We are sure you'll find a variety of positions that you'll be interested in.
      Don't hesitate to reach out to your recruiting team if you have any questions.`,
      buttons: [
        {
          text: 'Complete this tour!',
          action: tour.complete
        },
      ]
    });

    tour.start();
  });
</script>
