<template>
  <div class="relative">
    <button
      v-if="!this.usingExpandedFormInputs"
      class="text-indigo-600"
      :class="!this.expandedForm ? 'hover:text-indigo-500' : ''"
      @click.prevent="this.expandForm = true"
      :aria-expanded="this.expandedForm"
      :disabled="this.expandedForm"
    >
      <BarsArrowDownIcon
        v-if="!this.expandedForm"
        class="h-6 w-6 inline"
      />
      <span v-if="!this.expandedForm">I need more name fields...</span>
      <span v-if="this.expandedForm">There you go!</span>
    </button>

    <NormalInput
      v-if="this.expandedForm"
      name="name_prefix"
      label="Prefix"
      :value="old.name_prefix || user.name_prefix"
      :error="this.error('name_prefix')"
    />

    <NormalInput
      name="first_name"
      label="First Name"
      required
      :value="old.first_name || user.first_name"
      :error="this.error('first_name')"
    />

    <NormalInput
      v-if="this.expandedForm"
      name="middle_name"
      label="Middle Name"
      :value="old.middle_name || user.middle_name"
      :error="this.error('middle_name')"
    />

    <NormalInput
      name="last_name"
      label="Last Name"
      required
      :value="old.last_name || user.last_name"
      :error="this.error('last_name')"
    />

    <NormalInput
      v-if="this.expandedForm"
      name="name_suffix"
      label="Suffix"
      :value="old.name_suffix || user.name_suffix"
      :error="this.error('name_suffix')"
    />
  </div>
</template>

<script>
 import NormalInput from './NormalInput.vue';
  import { ArrowsPointingOutIcon, BarsArrowDownIcon } from '@heroicons/vue/24/outline';
  export default {
    components: {
      NormalInput,
      ArrowsPointingOutIcon,
      BarsArrowDownIcon,
    },
    data() {
      return {
        expandForm: false,
      };
    },
    methods:{
      error(field){
        return this.errors[field] ? this.errors[field][0] : null;
      }
    },
    computed: {
      expandedForm() {
        return this.expandForm || this.usingExpandedFormInputs;
      },
      usingExpandedFormInputs(){
        return this.user.name_prefix || this.old.name_prefix ||
        this.user.middle_name || this.old.middle_name ||
        this.user.name_suffix || this.old.name_suffix
      }
    },
    props: {
      user: {
        type: Object,
        required: false,
        default: () => {
          return {};
        },
      },
      old: {
        type: Object,
        required: false,
        default: () => {
          return {};
        },
      },
      errors: {
        type: Object,
        required: false,
        default: () => {
          return {};
        },
      },
    },
  };
</script>
