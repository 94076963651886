import { useShepherd } from 'vue-shepherd'
import { onUnmounted, defineEmits } from 'vue';
import { offset } from '@floating-ui/dom';

export function useTour(emit){
  const tour = useShepherd({
    useModalOverlay: true,
    exitOnEsc: true,
    scrollTo: true,
    defaultStepOptions:{
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 20 })]
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: `Hide the Sherpa`,
          secondary: true,
          action() {
            this.hideSherpa = true;
            return this.cancel()
          }
        },
        {
          text: 'Back',
          secondary: true,
          action() {
            return this.back();
          }
        },
        {
          text: 'Next',
          action() {
            return this.next();
          }
        }
      ]
    }
  });

  onUnmounted(() => {
    tour.cancel();
  });

  tour.on('cancel', (data) => {
    emit('cancel', data);
  });

  tour.on('complete', (data) => {
    emit('complete', data);
  });

  return tour;
}
