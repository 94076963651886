<template>
  <div>
    <select-single
      v-if="!this.defaultTeamSelector"
      @single-select="this.onClientSelect"
      :options="this.sortedClients"
      :selected-option="this.selectedClient"
      name="client_id"
      dusk="client"
      label="For Client"
      :error="this.clientError"
      placeholder="Choose a Client"
      :disabled="this.search.id"
    />

    <select-multiple
      :options="this.sortedManagers"
      :selected-options="this.mySelectedManagers"
      name="managers[]"
      dusk="managers"
      :label="this.defaultTeamSelector ? 'Default Hiring Team' : 'Client Hiring Team'"
      :error="managersError"
    />

    <select-multiple
      :options="this.sortedRecruiters"
      :selected-options="this.mySelectedRecruiters"
      name="recruiters[]"
      dusk="recruiters"
      :label="this.defaultTeamSelector ? 'Default Recruiting Team' : 'Recruiting Team'"
      :error="this.recruitersError"
    />

    <select-multiple
      :options="this.sortedAdmins"
      :selected-options="this.mySelectedAdmins"
      name="admins[]"
      dusk="admins"
      :label="this.defaultTeamSelector ? 'Default Admin Team' : 'Admin Team'"
      :error="this.adminsError"
    />
  </div>
</template>

<script>
import SelectSingle from './SelectSingle.vue';
import SelectMultiple from './SelectMultiple.vue';
export default {
  components:{
    SelectSingle,
    SelectMultiple,
  },
  props:{
    defaultTeamSelector:{
      type: Boolean,
      default: false
    },
    search:{
      type: Object,
    },
    clients:{
      type: Array,
    },
    recruiters:{
      type: Array,
    },
    admins:{
      type: Array,
    },
    selectedClient:{
      type: Object,
    },
    selectedManagers:{
      type: Array,
    },
    selectedRecruiters:{
      type: Array,
    },
    selectedAdmins:{
      type: Array,
    },
    clientError:{
      type: String,
      default: ''
    },
    managersError:{
      type: String,
      default: ''
    },
    adminsError:{
      type: String,
      default: ''
    },
  },
  data(){
    return {
      client: this.selectedClient,
      mySelectedManagers: this.selectedManagers,
      mySelectedAdmins: this.selectedAdmins,
      mySelectedRecruiters: this.selectedRecruiters,
    };
  },
  methods:{
    onClientSelect(client){
      this.client = client;
      this.mySelectedManagers = client.default_managers;
      this.mySelectedRecruiters = client.default_recruiters;
      this.mySelectedAdmins = client.default_admins;
    }
  },
  computed:{
    sortedClients(){
      return this.clients.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedManagers(){
      return this.client?.managers?.sort((a, b) => a.last_name.localeCompare(b.last_name));
    },
    sortedRecruiters(){
      return this.recruiters.sort((a, b) => a.last_name.localeCompare(b.last_name));
    },
    sortedAdmins(){
      return this.admins.sort((a, b) => a.last_name.localeCompare(b.last_name));
    }
  }
}
</script>
