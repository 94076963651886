<template>
<div class="bg-white p-4 rounded">
  <input
    class="form-input block w-full transition duration-150 ease-in-out border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
    type="text"
    :value="this.video.title"
    @change="this.updateTitle"
  />

  <div class="mt-4">
    <ButtonSecondary
      @click="this.titleSlideModalOpen = true"
    >
      Create<br>Title Slide
    </ButtonSecondary>

    <ButtonSecondary
      class="relative"
      @click="this.importFromZoom = true"
      :disabled="this.importing"
    >
      Import from
      <img src="/images/zoom_logo_2023.svg" alt="zoom" class="h-4 rounded-md mb-1">
      <ScaleLoader
        v-if="this.importing"
        class="absolute inset-0 w-full h-full flex items-center justify-center"
        color="#FFA500"
      />
    </ButtonSecondary>
  </div>

  <FileUploadMultiple
    name="originals[]"
    dusk="originals"
    instructions="Smaller clips will make for a faster rendering experience"
    :errors="this.uploadErrors"
    :emits-events="true"
    :uploadProgress="this.uploadProgress"
    @filesSelected="this.uploadSourceFiles"
  ></FileUploadMultiple>

  <div>
    <div>
      <h3>Source Files</h3>
      <p class="text-xs text-gray-600 my-2">
        These Source Files come from video files
        uploaded from your device, imported from Zoom
        or Title Slides you create here.
      </p>
      <Draggable
        class="grid grid-cols-4 gap-x-4 gap-y-8 lg:grid-cols-6 lg:gap-x-6"
        v-model="this.video.sourceFiles"
        :clone="this.createClipFromSource"
        :group="{ name: 'cue', pull: 'clone', put: false }"
        item-key="name"
      >
        <template #item="{ element }">
          <div>
            <SourceFile
              :sourceFile="element"
              @remove="this.removeSourceFile(element)"
              @newSourceFile="this.newSourceFile"
              @updateTitleSlide="this.updateTitleSlide"
              :trimSourceFileUrl="this.trimSourceFileUrl"
            />
          </div>
        </template>
      </Draggable>
    </div>

    <div
      v-if="this.sourceFileErrors.length"
      class="text-red-500 text-xs"
    >
      <p v-for="error in this.sourceFileErrors">
        {{ error }}
      </p>
    </div>

    <div class="border-t-2 mt-4 mb-10">
      <h3 class="my-2">
        Timelines
        <ButtonSecondary
          @click="this.createTimeline"
        >
          Add Timeline
        </ButtonSecondary>
      </h3>
      <ul class="flex flex-wrap text-sm font-medium text-center border-b border-gray-200">
        <li v-for="timeline in this.timelines" class="me-2 relative">
          <div>
            <button
              v-if="timeline.video_state === 'DONE'"
              @click="this.timelinePreviewModalOpen = true"
              title="Click to view the rendered video"
              class="bottom-0 right-1 absolute"
            >
              <VideoCameraIcon
                v-if="timeline.video_state === 'DONE'"
                title="Video has been rendered"
                class="h-4 w-4 text-green-500"
              />
            </button>
            <div v-else-if="timeline.video_state === 'PROCESSING'">
              <CloudIcon
                title="Video is processing"
                class="h-4 w-4 bottom-0 right-1 text-blue-400 absolute animate-ping"
              />
              <CloudIcon
                title="Video is processing"
                class="h-4 w-4 bottom-0 right-1 text-blue-500 absolute"
              />
            </div>
            <div
              v-else-if="timeline.video_state === 'FAILED'"
              :title="timeline.last_error"
              class="bottom-0 right-1 absolute"
            >
              <VideoCameraSlashIcon
                class="h-4 w-4 text-red-500"
              />
            </div>
          </div>
          <button
            @click="this.selectTimeline(timeline)"
            aria-current="page"
            class="inline-block p-4 bg-gray-100 rounded-t-lg hover:bg-gray-200"
            :class="{'bg-indigo-400 hover:bg-indigo-400 text-white border-r border-t border-l border-grey-400': timeline === this.timeline}"
          >
            {{timeline.title}}
          </button>
        </li>
      </ul>
      <div v-if="this.timeline.id">
        <div>
          <div class="flex justify-between items-center">
            <div>
              <button v-if="this.editTimelineTitle">
                <XCircleIcon
                  @click="this.editTimelineTitle = false"
                  class="mr-2 h-5 w-5 text-gray-500 hover:text-gray-800"
                />
              </button>
              <button v-else>
                <PencilSquareIcon
                  @click="this.editTimelineTitle = true"
                  class="mr-2 h-5 w-5 text-gray-500 hover:text-gray-800"
                />
              </button>
              <input v-if="this.editTimelineTitle"
                class="form-input inline-block transition duration-150 ease-in-out border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
                type="text"
                :value="this.timeline.title"
                @change="this.updateTimelineTitle($event.target.value)"

              />
              <h4 v-else
                class="my-2 inline-block"
                @click="this.editTimelineTitle = true"
              >
                {{timeline.title}}
              </h4>
            </div>
            <DeleteButton
              class="inline-block"
              :route="this.deleteCurrentTimelineUrl"
              buttonText="Delete Timeline"
              warning="Are you sure you want to delete this timeline?"
            />
          </div>
          <div
            v-if="timeline.video_state === 'FAILED'"
            class="text-red-500 text-xs"
          >
            <p>
              {{ timeline.last_error }}
            </p>
          </div>
        </div>
        <p class="text-xs text-gray-600 my-2">
          Drag and drop Source Files onto the timeline to create clips.
          Rearrange them to your liking and then click Render Timeline.
          Rendering video can take some time, so please be patient to view the final product.
        </p>
        <div
          v-if="this.timeline.duration"
          class="text-xs text-gray-500 text-right"
        >
          {{ this.timeline.durationDisplay }}
        </div>
        <Draggable
          class="bg-gray-200 border-b border-gray-400 flex py-2"
          style="min-height: 25px;"
          :modelValue="this.timeline.clips"
          :list="null"
          :group="{ name: 'cue'}"
          @change="this.modifyClip"
          item-key="name"
        >
          <template #item="{ element, index }">
            <TimelineThumbnail
              :timeline="this.timeline"
              :clip="element"
              @remove="this.removeClip(index)"
            />
          </template>
        </Draggable>
        <form
          class="flex flex-col items-center justify-center mt-8"
          method="POST"
          :action="this.renderUrl"
        >
          <input type="hidden" name="_token" :value="this.csrf">
          <ButtonPrimary
            type="submit"
            :disabled="this.timeline.clips.length === 0"
          >
            Render Timeline
          </ButtonPrimary>
        </form>
      </div>
    </div>


    <SimpleModal
      :open="this.timelinePreviewModalOpen"
      :hideCancel="true"
      :hideConfirm="true"
      :fullWidth="true"
      @cancel="this.timelinePreviewModalOpen = false"
    >
      <template v-slot:content>
        <VideoPreview
          :sourceFile="this.timeline.renderedVideo"
        />
      </template>
    </SimpleModal>

    <SimpleModal
      :open="this.titleSlideModalOpen"
      :hideCancel="true"
      :hideConfirm="true"
      :fullWidth="true"
      @cancel="this.titleSlideModalOpen = false"
    >
      <template v-slot:content>
        <TitleSlideEditor
          @save="this.createTitleSlide"
          @cancel="this.titleSlideModalOpen = false"
        ></TitleSlideEditor>
      </template>
    </SimpleModal>

    <SimpleModal
      :open="this.importFromZoom"
      :hideCancel="true"
      :hideConfirm="true"
      :fullWidth="true"
      @cancel="this.importFromZoom = false"
    >
      <template v-slot:content>
        <ZoomImport
          @import="this.importSourceFiles"
        ></ZoomImport>
      </template>
    </SimpleModal>

  </div>

</div>
</template>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';
import ButtonPrimary from './ButtonPrimary.vue';
import ButtonSecondary from './ButtonSecondary.vue';
import DeleteButton from './DeleteButton.vue'
import FileUploadMultiple from './FileUploadMultiple.vue';
import TimelineThumbnail from './VideoEditor/TimelineThumbnail.vue';
import Draggable from "vuedraggable";
import Timeline from './VideoEditor/Timeline';
import SourceFile from './VideoEditor/SourceFile.vue';
import Clip from './VideoEditor/Clip';
import TitleSlideEditor from './VideoEditor/TitleSlideEditor.vue';
import SimpleModal from './SimpleModal.vue';
import ZoomImport from './ZoomImport.vue';
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import VideoPreview from './VideoPreview.vue';
import {
  XCircleIcon,
  VideoCameraSlashIcon,
  VideoCameraIcon,
  EyeIcon,
  PencilSquareIcon,
  CloudIcon,
} from '@heroicons/vue/24/solid'


export default defineComponent({
  components:{
    Draggable,
    FileUploadMultiple,
    TimelineThumbnail,
    SourceFile,
    SimpleModal,
    TitleSlideEditor,
    ButtonPrimary,
    ButtonSecondary,
    DeleteButton,
    ZoomImport,
    ScaleLoader,
    PencilSquareIcon,
    XCircleIcon,
    VideoCameraSlashIcon,
    VideoCameraIcon,
    EyeIcon,
    CloudIcon,
    VideoPreview,
  },
  mounted() {
    this.listen();
  },
  props: {
    editorVideo: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    getVideoUrl: String,
    updateVideoUrl: String,
    uploadSourceFileUrl: String,
    updateSourceFileUrl: String,
    importSourceFileUrl: String,
    removeSourceFileUrl: String,
    trimSourceFileUrl: String,
    createClipUrl: String,
    deleteClipUrl: String,
    updateTimelineUrl: String,
    createTimelineUrl: String,
    deleteTimelineUrl: String,
    renderTimelineUrl: String,
  },

  data(){
    return {
      uploadErrors: [],
      sourceFileErrors:[],
      timelineErrors: [],
      cueFiles: [],
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      video: JSON.parse(JSON.stringify(this.editorVideo)),
      uploadProgress: 0,
      selectedTimelineId: null,
      titleSlideModalOpen: false,
      timelinePreviewModalOpen: false,
      importFromZoom: false,
      editTimelineTitle: false,
    }
  },
  computed:{
    timeline(){
      if(!this.selectedTimelineId){
        return this.timelines[0] || {};
      }
      return this.timelines.find(t => t.id === this.selectedTimelineId);
    },
    timelines(){
      return this.video.timelines.map(t => new Timeline(t));
    },
    renderUrl(){
      return this.renderTimelineUrl.replace('000', this.timeline.id);
    },
    deleteCurrentTimelineUrl(){
      return this.deleteTimelineUrl.replace('000', this.timeline.id);
    }
  },
  methods:{
    listen(){
      Echo.private(`video-editor`)
        .listen('SourceFileRendered', ({sourceFile}) => {
          console.log('sourcefile rendered event received')
          this.refreshVideo();
        })
        .listen('TimelineRendered', ({sourceFile}) => {
          console.log('timeline rendered event received')
          this.refreshVideo();
        });
    },

    async updateTitle(event){
      await axios.put(this.updateVideoUrl, {
        title: event.target.value,
      });
      await this.refreshVideo();
    },

    async uploadSourceFiles(files){
      let promises = files.map(file => this.uploadSourceFile(file) );
      await Promise.all(promises);
      await this.refreshVideo()
        .finally( ()=> this.uploadProgress = 0 );
    },

    async uploadSourceFile(file){
      const formData = new FormData();
      formData.append('content', file);
      return await axios.post(
        this.uploadSourceFileUrl,
        formData,
        {onUploadProgress: this.onUploadProgress}
      );
    },

    onUploadProgress(progressEvent){
      this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    },

    async importSourceFiles(imports){
      let promises = imports.map(i => this.importSourceFile(i));
      try{
        this.importing = true;
        this.importFromZoom = false;
        await Promise.all(promises);
      } catch (error){
        this.sourceFileErrors.push(error);
      }
      this.importing = false;
      await this.refreshVideo();
    },

    async importSourceFile({url, type, duration, name}){
      let {data} = await axios.post(this.importSourceFileUrl, {
        url,
        type,
        duration,
        name,
      });

      this.video.sourceFiles.push(data);
    },

    async removeSourceFile(sourceFile){
      this.video.sourceFiles = this.video
        .sourceFiles
        .filter(file => file.id !== sourceFile.id);

      let url = this.removeSourceFileUrl
        .replace('000', sourceFile.id);

      try{
        await axios.delete(url);
        this.sourceFileErrors = [];
      } catch (error){
        this.sourceFileErrors.push(error.response.data.error);
      }
      await this.refreshVideo();
    },

    async newSourceFile(event){
      await this.refreshVideo();
    },

    async refreshVideo(){
      return axios.get(this.getVideoUrl)
        .then(({data}) => {
          Object.assign(this.video, data);
        });
    },

    createClipFromSource(sourceFile){
      if(sourceFile.type === 'html'){
        return new Clip({
          type: sourceFile.type,
          length: 5,
          start: 0,
          sourceFile,
        });
      }
      return new Clip({
        type: sourceFile.type,
        preview: sourceFile.preview_url,
        src: sourceFile.original_url,
        length: sourceFile.duration,
        start: 0,
        sourceFile,
      });
    },

    modifyClip(event) {
      if(event.added){
        const {element, newIndex} = event.added;
        this.addClip(element, newIndex);
      } else if(event.moved){
        const {newIndex, oldIndex} = event.moved;
        this.moveClip(newIndex, oldIndex);
      }
      return false;
    },

    async addClip(clip, newIndex){
      this.timeline.addClip(clip, newIndex)
      let url = this.createClipUrl.replace('000', this.timeline.id)
      let {data} = await axios.post(url, clip);
      await this.refreshTimeline(data);
    },

    async moveClip(newIndex, oldIndex){
      this.timeline.moveClip(newIndex, oldIndex);
      await this.saveTimeline();
    },

    async removeClip(index){
      let clip = this.timeline.removeClip(index);
      let url = this.deleteClipUrl
        .replace('000', this.timeline.id)
        .replace('111', clip.id);
      let response = await axios.delete(url);
      this.refreshTimeline(response.data);
    },

    async createTitleSlide(props){
      let {data} = await axios.post(
        this.uploadSourceFileUrl,
        props,
      );
      this.video.sourceFiles.push(data);
      this.titleSlideModalOpen = false;
    },

    async updateTitleSlide(props){
      let {data} = await axios.put(
        this.updateSourceFileUrl.replace('000', props.id),
        props,
      );
      let sourceFile = this.video.sourceFiles.find(sourceFile => sourceFile.id === props.id);
      Object.assign(sourceFile, data);
    },

    selectTimeline(timeline){
      this.selectedTimelineId = timeline.id;
      this.editTimelineTitle = false;
    },

    async createTimeline(){
      let {data} = await axios.post(this.createTimelineUrl);
      let timeline = new Timeline(data);
      this.video.timelines = [...this.video.timelines, timeline];
      this.timeline = timeline
    },

    async updateTimelineTitle(title){
      this.timeline.title = title;
      this.editTimelineTitle = false;
      await this.saveTimeline();
    },

    async saveTimeline(){
      let url = this.updateTimelineUrl.replace('000', this.timeline.id);
      const response = await axios.put(url, {
        timeline: this.timeline
      });
      this.refreshTimeline(response.data);
    },

    refreshTimeline(data){
      let timeline = new Timeline(data);
      this.video.timelines = this.video.timelines.map(t => {
        if(t.id === timeline.id){
          return timeline;
        }
        return t;
      });
    }
  },
})
</script>
