<template>
  <div class="mt-6 sm:mt-5">
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">

      <label
        :for="name"
        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        {{label}}
      </label>


      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="relative rounded-md shadow-sm" data-select>
          <span class="inline-block w-full rounded-md shadow-sm">
            <button
              @click="toggleSelect"
              type="button"
              :dusk="dusk"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 py-2 text-left focus:outline-none focus:ring-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              <span class="w-full inline-flex flex-wrap space-x-2 truncate">
                <span
                  v-for="option in selectedOptions"
                  :key="option.id"
                  class="text-gray-900 bg-gray-100 px-4 py-1 my-1 rounded-full"
                >
                  {{option.name}}
                </span>
                <span
                  v-if="selectedOptions.length === 0"
                  class="text-gray-500"
                >
                  {{placeholder}}
                </span>
              </span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                  <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </button>

            <div
              v-if="error"
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
          </span>

          <div
            v-if="selectOpen"
            class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
          >
            <ul
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              class="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >

              <li
                v-for="option in options"
                :key="option.id"
                @click.stop="selectOption(option)"
                :id="`option-${option.id}`"
                :dusk="`option-${option.id}`"
                role="option"
                class="cursor-pointer select-none relative py-2 pl-3 pr-9"
              >
                <div
                  class="space-x-2"
                  v-bind:class="isSelected(option) ? 'font-semibold' : 'font-normal'"
                >
                  {{option.name}}
                </div>

                <span
                  v-if="isSelected(option)"
                  class="absolute inset-y-0 right-0 flex items-center pr-4">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>

              <li
                v-if="options.length === 0"
                class="cursor-not-allowed select-none relative py-2 pl-3 pr-9 text-gray-500 text-light italic"
              >
                No options available
              </li>

            </ul>
          </div>
        </div>
        <p v-if="error" class="mt-2 text-sm text-red-600">
          {{error}}
        </p>
      </div>
    </div>
    <input
      v-for="option in selectedOptions"
      :key="option.id"
      type="hidden"
      :name="name"
      :value="option.id"
    >
  </div>
</template>

<script>
export default {
  props:{
    options:{
      type:[Array,Object],
      default:[],
    },
    selectedOptions:{
      type:Array,
      default:null
    },
    dusk:{
      type:String,
      default:''
    },
    name:{
      type:String,
      default:''
    },
    label:{
      type:String,
      default:''
    },
    placeholder:{
      type:String,
      default:''
    },
    error:{
      type:String,
      default:''
    }
  },

  data(){
    return {
      selectOpen:false,
      highlight:null,
    }
  },

  created(){
    document.addEventListener('click',this.closeSelect);
  },
  beforeUnmount(){
    document.removeEventListener('click',this.closeSelect);
  },

  methods:{
    toggleSelect(){
      this.selectOpen = !this.selectOpen;
    },
    closeSelect(event){
      let thisWasClicked = this.$el
        .querySelector('[data-select]')
        .contains(event.target);

      if(thisWasClicked === false){
        this.selectOpen = false;
      }
    },
    isSelected(option){
      return this.selectedOptions.find(s=>s.id === option.id)
    },
    selectOption(option){
      if(this.isSelected(option)){
        let selectedOption = this.isSelected(option);
        let index = this.selectedOptions.indexOf(selectedOption);
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(option);
      }
      this.$forceUpdate();
      this.$emit('multiple-select',this.selectedOptions)
    },
  }

}
</script>
