<template>
  <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
    <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
      {{label}}
    </label>
    <div class="mt-2 sm:mt-0 sm:col-span-2">
      <div class="flex items-center">
        <slot name="icon">
          <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
              <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </span>
        </slot>
        <span class="ml-5 rounded-md shadow-sm">
          <button
            @click="selectFile"
            type="button"
            class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          >
            Change
          </button>
          <input
            @change="fileSelected"
            :accept="mimes"
            :name="name"
            :dusk="dusk"
            type="file"
            class="hidden"
          />
        </span>
      </div>
      <p
        class="text-gray-500 text-sm"
        v-if="instructions"
      >
        {{instructions}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    label:String,
    name:String,
    dusk:String,
    ref: String,
    instructions:String,
    mimes:{
      type: String,
      default: '*'
    }
  },
  emits: {
    change:null
  },
  methods:{
    selectFile(e){
      this.$el.querySelector('input').click();
    },
    fileSelected(e){
      let file = e.target.files[0];
      this.$emit('change', file);
    }
  }
}
</script>
