<template>
<ul role="list">
  <CustomVideoListItem
    v-for="video in videoList"
    :key="video.id"
    :video="video"
  />
</ul>
</template>

<script>

import CustomVideoListItem from "./CustomVideoListItem.vue";

export default {
  components:{
    CustomVideoListItem,
  },
  mounted() {
    this.listen();
  },
  props: {
    videos: {
      type: Array,
      required: true
    },
  },
  data(){
    return {
      oldVideos: this.videos.map(video => JSON.parse(JSON.stringify(video)) ),
      newVideos: []
    }
  },
  computed: {
    videoList() {
      return this.oldVideos.concat(this.newVideos);
    }
  },
  methods:{
    listen(){
      Echo.private(`video-editor`)
        .listen('TimelineRendered', ({timeline}) => {
          console.log('timeline rendered event received')
          this.respondToSocketTimelineEvent(timeline);
        })
        .listen('VideoAdded', ({video}) => {
          console.log('added event received')
          this.newVideos.push(video);
        });
    },
    respondToSocketTimelineEvent(timeline){
      this.videoList
        .forEach(video => {
          let rendered = video.timelines.filter(t => t.id === timeline.id)[0];
          if(rendered){
            video.timelines = [timeline, ...video.timelines];
          }
        });
    }
  }
}
</script>
