<script setup>
  import { useTour } from '../Tour.js'
  import { onMounted, defineEmits } from 'vue'

  const emits = defineEmits(['complete','cancel']);
  const tour = useTour(emits);

  onMounted(() =>  {
    tour.addStep({
      title: 'Company Details',
      text: `Here you can find more information about a particular company to see if
      you feel like their culture is a good match for your values.`,
      buttons: [
        {
          text: 'Continue',
          action: tour.next
        },
      ]
    });

    tour.addStep({
      attachTo: { element: '#description', on: 'bottom' },
      title: 'Position Description',
      text: `Here you can see a brief description of the organization.`,
    });

    tour.addStep({
      attachTo: { element: '#media', on: 'top' },
      title: 'Media Files',
      text: `You'll also find a collection of media assets that will
      help you get a better feel for the company culture.`,
    });

    tour.addStep({
      attachTo: { element: '#positions', on: 'top' },
      title: 'Hiring Team',
      text: `These are available positions within this organization. Feel free to browse them at your own pace.`,
      buttons: [
        {
          text: 'Complete this tour!',
          action: tour.complete
        },
      ]
    });

    tour.start();
  });
</script>
