<template>
  <!-- Profile dropdown -->
    <div class="ml-3 relative">
      <div>
        <!-- p-1 text-gray-900 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring focus:text-gray-500 -->
        <button
          @click.stop="toggleOpen"
          class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring"
          id="help"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <svg class="h-12 w-12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10 7C9.63113 7 9.3076 7.19922 9.13318 7.50073C8.85664 7.97879 8.24491 8.14215 7.76685 7.86561C7.28879 7.58906 7.12543 6.97733 7.40197 6.49927C7.91918 5.60518 8.88833 5 10 5C11.6569 5 13 6.34315 13 8C13 9.30622 12.1652 10.4175 11 10.8293V11C11 11.5523 10.5523 12 10 12C9.44773 12 9.00001 11.5523 9.00001 11V10C9.00001 9.44772 9.44773 9 10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15Z" fill="#4A5568"/>
          </svg>
        </button>
      </div>

      <transition
        enter-active-class= "transition ease-out duration-100"
        active-class= "transform opacity-0 scale-95"
        active-to-class= "transform opacity-100 scale-100"
        leave-active-class= "transition ease-in duration-75"
        leave-class= "transform opacity-100 scale-100"
        leave-to-class= "transform opacity-0 scale-95"
      >
        <div
          id="help-menu-dropdown"
          v-show="isOpen"
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
          <div class="py-1 rounded-md bg-white ring-1 ring-black/5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  props:['user'],
  data(){
    return {
      isOpen: false
    }
  },
  methods:{
    toggleOpen(){
      if(!this.isOpen){
        window.addEventListener('click', this.close);
        this.isOpen = true;
      } else {
        this.close();
      }
    },
    close(){
      window.removeEventListener('click', this.close);
      this.isOpen = false;
  }
  }
}
</script>
