<template>
  <component
    :is="tours[currentTour]"
    @complete="completeTour"
    @cancel="cancelTour"
  />
</template>

<script setup>
import axios from 'axios'
import { defineProps, computed } from 'vue'
import DashboardAdmin from './tours/DashboardAdmin.vue'
import DashboardCandidate from './tours/DashboardCandidate.vue'
import SearchCandidateIndexCandidate from './tours/SearchCandidateIndexCandidate.vue'
import SearchCandidateViewCandidate from './tours/SearchCandidateViewCandidate.vue'
import ClientCandidateViewCandidate from './tours/ClientCandidateViewCandidate.vue'

const props = defineProps(['user', 'route']);

const tours = {
  DashboardAdmin,
  DashboardCandidate,
  SearchCandidateIndexCandidate,
  SearchCandidateViewCandidate,
  ClientCandidateViewCandidate
}

const currentTour = computed(
  () => {
    const {completed_tours, onboarding_hidden} = props.user;
    const completed = completed_tours.some(tour => tour.route === props.route);
    if(completed || onboarding_hidden) return;
    return `${strongCase(props.route)}${strongCase(props.user.type)}`
  }
)

function strongCase(str){
  return str.replace(/[\.-]/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

async function completeTour({index, tour}){
  let {data} = await axios.post('/api/onboarding/complete-route',{
    route: props.route
  });
  console.log(data);
}

async function cancelTour({index, tour}){
  if(tour.hideSherpa){
    let {data} = await axios.post('/api/onboarding/disable')
    console.log(data);
  }
}

</script>
