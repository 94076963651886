<script setup>
  import { useTour } from '../Tour.js'
  import { onMounted, defineEmits } from 'vue'

  const emits = defineEmits(['complete','cancel']);
  const tour = useTour(emits);

  onMounted(() =>  {
    tour.addStep({
      title: 'Position Detail',
      text: `This is your first look at an available Position.`,
      buttons: [
        {
          text: 'Continue',
          action: tour.next
        },
      ]
    });

    tour.addStep({
      attachTo: { element: '#description', on: 'bottom' },
      title: 'Position Description',
      text: `Here you can see a brief description of the position.`,
    });

    tour.addStep({
      attachTo: { element: '#hiring-team', on: 'top' },
      title: 'Hiring Team',
      text: `These people are the team memebers from the company
      that are hiring for this position, as well as your recruiting
      and mentoring team.`,
    });

    tour.addStep({
      attachTo: { element: '#media', on: 'top' },
      title: 'Media Files',
      text: `You'll also find a collection of media assets that will
      help you get a better feel for the position and the company culture.`,
    });

    tour.addStep({
      title: 'Express Interest',
      attachTo: { element: '[dusk="follow-up"]', on: 'bottom' },
      text: `Finally, if you're interested in this position,
      you can click the Express Interest button to let the hiring team know.`,
      buttons: [
        {
          text: 'Complete this tour!',
          action: tour.complete
        },
      ]
    });

    tour.start();
  });
</script>
