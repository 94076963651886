<template>
  <div class="md:hidden">
    <div v-show="isOpen" class="fixed inset-0 flex z-40">

      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-show="isOpen" class="fixed inset-0">
          <div
            v-on:click="close"
            class="absolute inset-0 bg-gray-600 opacity-75"
          ></div>
        </div>
      </transition>

      <transition
        enter-active-class = "transition ease-in-out duration-300 transform"
        enter-class= "-translate-x-full"
        enter-to-class= "translate-x-0"
        leave-active-class= "transition ease-in-out duration-300 transform"
        leave-class= "translate-x-0"
        leave-to-class= "-translate-x-full"
      >
        <div
          v-if="isOpen"
          id="mobile-nav"
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white"
        >
          <div class="absolute top-0 right-0 -mr-14 p-1">
            <button
              v-on:click="close"
              class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar"
            >
              <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            v-if="logo"
            class="shrink-0 flex items-center px-4"
          >
            <img class="w-auto px-16" :src="logo" alt="logo">
          </div>
          <div
            v-else
            class="text-xl shrink-0 flex items-center px-4 text-gray-600"
          >
            Name or Logo Here
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <nav class="px-2">
              <slot name="links"></slot>
              <slot></slot>
            </nav>
          </div>
        </div>
      </transition>
      <div class="shrink-0 w-14">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    logo:{
      type:String,
      default:''
    }
  },
  computed:{
    isOpen(){
      return this.$store.state.navOpen;
    }
  },
  methods:{
    close(){
      this.$store.commit('toggleNav');
    }
  }
}
</script>
