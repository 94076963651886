<script setup>
  import { useTour } from '../Tour.js'
  import { onMounted, defineEmits } from 'vue'

  const emits = defineEmits(['complete','cancel']);
  const tour = useTour(emits);

  onMounted(() =>  {
    tour.addStep({
      title: 'Welcome',
      text: `Thanks for joining This Way Up! We are glad to have you here.
      I'll be your sherpa for this trip, together we can help your career reach new heights.`,
      buttons: [
        {
          text: 'Guide Me!',
          action: tour.next
        },
      ]
    });

    tour.addStep({
      attachTo: { element: '#help', on: 'bottom' },
      title: 'Help Center',
      text: `More detailed help is always available here. You can also re-enable the tour for any page from here.`,
    });

    tour.addStep({
      attachTo: { element: '#user-menu', on: 'bottom' },
      title: 'User Menu',
      text: `In this menu you do things like; access your profile, change your password, and log out.`,
    });

    tour.addStep({
      attachTo: { element: '#get-started', on: 'bottom' },
      title: 'Getting Started',
      text: `Lets get started by browsing the available positions and selecting the ones that interest you.`,
      showOn(){
        return document.querySelector('#get-started');
      }
    });

    tour.addStep({
      attachTo: { element: '#assigned-searches', on: 'top' },
      title: 'Assigned Positions',
      text: `These are positions that your team has determined you are a good fit for.`,
      showOn(){
        return document.querySelector('#assigned-searches');
      }
    });

    tour.addStep({
      attachTo: { element: '#assigned-searches .search', on: 'top' },
      title: 'Assigned Positions',
      text: `You can see more details about each position, as well as
       get a better feel for the company by viewing their profile.`,
      showOn(){
        return document.querySelector('#assigned-searches');
      }
    });

    tour.addStep({
      attachTo: { element: '#interested-searches', on: 'top' },
      title: 'Your Positions',
      text: `These are positions that you have told the hiring team you're interested in.`,
      showOn(){
        return document.querySelector('#interested-searches');
      }
    });

    tour.addStep({
      title: 'Tour Complete',
      text: `Thanks for completing this tour!
      You can always re-enable the tour from the help center.
      If you have any questions or need help, please don't hesitate to ask.`,
      buttons: [
        {
          text: 'Complete this tour!',
          action: tour.complete
        },
      ]
    });

    tour.start();
  });
</script>
