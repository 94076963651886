<template>
<div>
  <FileUploadSingle
    @change="changeFile"
    label="Headshot"
    name="headshot"
    dusk="headshot"
    instructions="For best results, use an image at least 256px by 256px in png or jpg format."
    mimes="image/png,image/jpg"
  >
    <template v-if="props.user.headshot?.avatar" #icon>
      <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <img
          class="h-full"
          :src="props.user.headshot.avatar"
        >
      </span>
    </template>
    <template v-else #icon>
      <span class="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
        <svg class="h-10 w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
        </svg>
      </span>
    </template>
  </FileUploadSingle>

  <div
    v-if="headshot"
    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
  >
    <label for="headshot_crop" class="block text-sm leading-5 font-medium text-gray-700">
      Crop Headshot
    </label>

    <input type="hidden" name="headshot_crop" :value="JSON.stringify(headshot_crop)" />

    <div class="mt-2 sm:mt-0 sm:col-span-2">
      <Cropper
        class="w-full"
        ref="cropper"
        :src="headshot"
        :stencil-props="{aspectRatio: 1/1}"
        :default-size="defaultSize"
        :default-position="defaultPosition"
        imageRestriction="fit-area"
        :resizeImage="false"
        @change="changeCrop"
      />
    </div>
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue';
import FileUploadSingle from './FileUploadSingle.vue'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

const props = defineProps({
    user: {
        type: Object,
        default: {},
    },
});

const cropper = ref(null);
const headshot = ref(props.user.headshot?.original);
const headshot_crop = ref(props.user.headshot?.crop);

const defaultSize = ({imageSize}) =>{
  let size = Math.min(imageSize.width, imageSize.height);
  return props.user.headshot?.crop ?? {width: size, height: size};
}

const defaultPosition = ({ visibleArea, coordinates, imageSize }) => {
  return props.user.headshot?.crop ?? {
    left: 1,
    top: 1,
  };
};

const changeCrop = ({coordinates}) => {
  headshot_crop.value = coordinates;
}

const changeFile = file => {
  let reader = new FileReader();

  reader.onloadend = ()=>{
    const base64Image = reader.result
    if(props.user.headshot){
      props.user.headshot.crop = null
    }
    headshot.value = base64Image
  }

  reader.readAsDataURL(file);
}

</script>
