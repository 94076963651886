<template>
  <!-- Profile dropdown -->
    <div id="user-menu" class="ml-3 relative">
      <div>
        <button
          @click.stop="toggleOpen"
          class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring"
          id="user-menu-button"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <img
            class="h-12 w-12 rounded-full border-2"
            :src="user.headshot.avatar"
            alt=""
          >
        </button>
      </div>

      <transition
        enter-active-class= "transition ease-out duration-100"
        active-class= "transform opacity-0 scale-95"
        active-to-class= "transform opacity-100 scale-100"
        leave-active-class= "transition ease-in duration-75"
        leave-class= "transform opacity-100 scale-100"
        leave-to-class= "transform opacity-0 scale-95"
      >
        <div
          id="user-menu-dropdown"
          v-show="isOpen"
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
          <div class="py-1 rounded-md bg-white ring-1 ring-black/5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  props:['user'],
  data(){
    return {
      isOpen: false
    }
  },

  methods:{
    toggleOpen(){
      if(!this.isOpen){
        window.addEventListener('click', this.close);
        this.isOpen = true;
      } else {
        this.close();
      }
    },
    close(){
      window.removeEventListener('click', this.close);
      this.isOpen = false;
    }
  },
}
</script>
