<script setup>
  import { useTour } from '../Tour.js'
  import { onMounted, defineEmits } from 'vue'

  const emits = defineEmits(['complete','cancel']);
  const tour = useTour(emits);

  onMounted(() =>  {
    tour.addStep({
      title: 'Welcome',
      text: `Thanks for joining This Way Up! We are glad to have you here.
      I'll be your sherpa for this trip.
      Soon enough you'll be guiding others and will be well on your way to becoming a sherpa yourself.`,
      buttons: [
        {
          text: 'Guide Me!',
          action: tour.next
        },
      ]
    });

    tour.addStep({
      attachTo: { element: '#help', on: 'bottom' },
      title: 'Help Center',
      text: `More detailed help is always available here. You can also re-enable the tour for any page from here.`,
    });

    tour.addStep({
      attachTo: { element: '#user-menu', on: 'bottom' },
      title: 'User Menu',
      text: `In this menu you do things like; access your profile, change your password, and log out.`,
    });

    tour.addStep({
      attachTo: { element: '#stats', on: 'top' },
      title: 'Account Activity',
      text: `These are statistics about your account's activity.
      You can see how much activity has occurred in the last 30 days.
      Each of these buttons will take you to a summary on this page where you can see more details.`,
    });

    tour.addStep({
      title: 'Tour Complete',
      text: `Thanks for completing the dashboard tour!
      You can always re-enable the tour from the help center.
      If you have any questions or need help, please don't hesitate to ask.`,
      buttons: [
        {
          text: 'Complete this tour!',
          action: tour.complete
        },
      ]
    });


    // tour.addStep({
    //   attachTo: { element: '#new-candidates', on: 'top' },
    //   title: 'Candidates',
    //   text: `Here are any new Candidates that have been added or signed up in the last 30 days.`,
    //   showOn(){
    //     return document.querySelector('#new-candidates')
    //   }
    // });

    // tour.addStep({
    //   attachTo: { element: '#new-clients', on: 'top' },
    //   title: 'Clients',
    //   text: `These are new Clients that you or another admin have added.`,
    //   showOn(){
    //     return document.querySelector('#new-clients')
    //   },
    // });

    // tour.addStep({
    //   attachTo: { element: '#new-searches', on: 'top' },
    //   title: 'Contacts',
    //   text: `These are new Searches that have been created in the last 30 days.`,
    //   showOn(){
    //     return document.querySelector('#new-searches')
    //   }
    // });

    // tour.addStep({
    //   attachTo: { element: '#new-managers', on: 'top' },
    //   title: 'Hiring Managers',
    //   text: `These are new hiring managers that have been invited in the last 30 days.`,
    //   showOn(){
    //     return document.querySelector('#new-managers')
    //   }
    // });

    tour.start();
  });
</script>
